nav {
  color: #fff;
  z-index: 20;
  background: #333;
  justify-content: space-around;
  align-items: center;
  display: flex;
  position: absolute;
}

.nav-links {
  justify-content: center;
  max-width: 300px;
  display: flex;
}

.nav-links a, .nav-links button {
  color: #fff;
  border-bottom: 2px solid #0000;
  margin-bottom: 16px;
  font-family: Red Hat Display, Helvetica Neue, sans-serif;
  font-size: 22px;
  text-decoration: none;
  transition: all .5s;
  display: block;
  transform: translateX(0%);
}

.nav-links a.sub-menu-item {
  margin-bottom: 10px;
  padding-left: 15px;
  font-size: 20px;
}

.nav-links a:hover {
  color: #00a88f;
}

.burger {
  z-index: 30;
  display: none;
}

.burger div {
  background: #000;
  width: 25px;
  height: 3px;
  margin: 5px;
  transition: all .5s;
}

.burger.toggle div {
  background: #fff;
}

@media only screen and (width <= 1000px) {
  nav {
    justify-content: space-between;
    padding: 0 5vw;
  }

  .nav-links {
    align-items: left;
    background: #333;
    flex-direction: column;
    width: 270px;
    min-height: 100%;
    margin: 0;
    padding: 40px 0 40px 40px;
    transition: color .5s ease-in;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .burger {
    display: block;
  }
}

.nav-active {
  transform: translateX(0);
}

.toggle .line1 {
  transform: rotate(-45deg)translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg)translate(-5px, -6px);
}
/*# sourceMappingURL=index.5f329506.css.map */
