nav {
  background: #333;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  z-index: 20;
  position: absolute;
}
.nav-links {
  display: flex;
  justify-content: center;
  max-width: 300px;
}
.nav-links a,
.nav-links button {
  display: block;
  text-decoration: none;
  color: #fff;
  margin-bottom: 16px;
  border-bottom: 2px solid transparent;
  transition: 0.5s ease;
  transform: translateX(0%);
  font-family: "Red Hat Display", "Helvetica Neue", sans-serif;
  font-size: 22px;
}
.nav-links a.sub-menu-item {
  font-size: 20px;
  padding-left: 15px;
  margin-bottom: 10px;
}
.nav-links a:hover {
  color: #00a88f;
}
.burger {
  display: none;
  z-index: 30;
}
.burger div {
  width: 25px;
  height: 3px;
  background: #000;
  margin: 5px;
  transition: all 0.5s ease;
}
.burger.toggle div {
  background: white;
}

@media only screen and (max-width: 1000px) {
  nav {
    justify-content: space-between;
    padding: 0 5vw;
  }
  .nav-links {
    position: fixed;
    right: 0;
    top: 0;
    min-height: 100%;
    background: #333;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 270px;
    margin: 0;
    padding: 40px 0 40px 40px;
    transform: translateX(100%);
    transition: color 0.5s ease-in;
  }
  .burger {
    display: block;
  }
}
.nav-active {
  transform: translateX(0);
}
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
